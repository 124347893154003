import React from "react";
import { EcoLabelImg } from "./styles/EcolabelStyles";

export type Props = {
    url: string;
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, "src">;

const EcoLabel: React.FC<Props> = ({ url, ...rest }) => {
    return <EcoLabelImg src={url} alt="" {...rest} />;
};

export default EcoLabel;
