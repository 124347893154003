import styled, { css } from "styled-components";
import { theme } from "../theme/lexusTheme";

/**
 * ScreenreaderOnly is a utility component that will hide content from the screen,
 * and make it visible only to screen readers.
 * Documentation: https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html
 * @example
 * export const ScreenreaderOnly = styled.span`
 *  ${screenreaderOnly};
 * `;
 */
export const srOnlyStyle = css`
    /* The ":not" portions of the selector are allowing a means for any focusable element
    to become visible when focused/active by a user. So elements that normally can't receive focus,
    like paragrpahs, will not become visible if a user navigates through content via screen reader controls or a Tab key,
    but natively focusable elements, or elements with a non-negative tabindex will have these elements appear in the DOM on focus. */
    &:not(:focus):not(:active) {
        position: absolute;
        width: 0.1rem;
        height: 0.1rem;
        white-space: nowrap;
        overflow: hidden;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
    }
`;

/**
 * Screenreader Only text
 * e.g. for hidden text in an icon (button)
 */
export const SrOnly = styled.span`
    ${srOnlyStyle};
`;

/**
 * Skip link
 * e.g. for skipping content
 */
export const SkipLink = styled.a`
    display: inline-block;
    position: absolute;
    left: -10000px;
    inset-block-start: auto;
    width: 1px;
    height: 1px;
    padding: 1.6rem 2.4rem;
    text-align: center;
    color: ${theme.colors.white};
    background: ${theme.colors.azure};
    border: 0;
    transition: transform 0.3s;
    overflow: hidden;
    &:focus-visible {
        position: static;
        width: auto;
        height: auto;
        color: ${theme.colors.white};
    }
`;

export default SrOnly;
